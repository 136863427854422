<template>
  <section>
    <ShopSectionHeader
      title="Why choose us?"
      subtitle="We are a preferred collision repair shop by many insurance carriers. Also, we are family owned and operated since 1984."
    />
    <div class="tiles-container">
      <ShopService
        title="General Collision Repair"
        subtitle="Repair"
        description="If you've been involved in an accident we're the shop for you."
        image="../../assets/images/services/collision-repair.jpg"
        imageAlignment="left"
      />
      <ShopService
        title="Body Work"
        subtitle="Heavy and Light"
        description="We have four body men who aren't afraid of anything. They can repair or replace any part necessary."
        image="../../assets/images/services/body-work.jpg"
        imageAlignment="right"
      />
      <ShopService
        title="Paint"
        subtitle="Any size paint job!"
        description="We have three skilled painters. All of which stay very busy."
        image="../../assets/images/services/paint.jpg"
        imageAlignment="left"
      />
      <ShopService
        title="Free Estimates"
        subtitle="Free!"
        description="Need an estimate, but not sure if you're going to get the car repaired? Give us a call at 502-695-4477!"
        image="../../assets/images/services/free-estimates.jpg"
        imageAlignment="right"
      />
      <ShopService
        title="Quality Control"
        subtitle="Inspection"
        description="We have a fully operational wash bay and full time quality control employees."
        image="../../assets/images/services/quality-control.jpg"
        imageAlignment="left"
      />
      <ShopService
        title="We accept cash, check, and credit"
        subtitle="Payment"
        description="In addition to cash and check, we accept credit (Visa and MasterCard)."
        image="../../assets/images/services/payment.jpg"
        imageAlignment="right"
      />
    </div>
  </section>
</template>

<script>
import ShopService from "@/components/molecules/ShopService";
import ShopSectionHeader from "@/components/atoms/ShopSectionHeader";

export default {
  name: "ShopServices",
  components: {
    ShopService,
    ShopSectionHeader,
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/scss/placeholders";

section {
  @extend %container;
  padding: 5rem 0;
  margin: 0 auto;
  .tiles-container {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 200px;
  }
}
</style>
