import { dom, library } from "@fortawesome/fontawesome-svg-core";
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { createApp, h } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import VueLazyload from 'vue3-lazyload';
import App from './App.vue';
import ApplyView from "./views/ApplyView.vue";
import HomeView from "./views/HomeView.vue";
import SubmissionFail from "./views/SubmissionFail.vue";
import SubmissionSuccess from "./views/SubmissionSuccess.vue";
import VueGoogleMaps from '@fawmi/vue-google-maps';

library.add(fas);
library.add(fab);
library.add(far);
dom.watch();

const routes = [
  {
    path: "/",
    component: HomeView
  },
  {
    path: "/apply",
    component: ApplyView
  },
  {
    path: "/thanks",
    component: SubmissionSuccess
  },
  {
    path: "/oops",
    component: SubmissionFail
  },
  {
    path: "/*", // if no path before this one is found redirect to home
    redirect: "/"
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
  }
});

const app = createApp({
  render: () => h(App)
});

app.use(router);
app.use(VueLazyload);
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyC1D5Psdwdlqm6dkL8atxWTNhA7DGByW3o',
  },
});

app.component("font-awesome-icon", FontAwesomeIcon);

app.mount('#app');

const html = document.querySelector("html");
html.dataset.theme = 'theme-light';