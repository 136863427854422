<template>
  <section id="team">
    <ShopSectionHeader
      title="Meet the Team!"
      subtitle="We're proudly family owned and operated!"
    />
    <div class="team">
      <TeamProfile
        class="member"
        profileImage="../../assets/images/team/john.jpg"
        bgImage="../../assets/images/team/john-at-work.jpg"
        name="John Long"
        jobTitle="Owner"
        message="John, son of Peggy and JB, has worked in nearly every position at the shop. He is currently the manager, and has been for 20 years. There are three guys who make estimates at the shop (JB, John, and Karlen). John is always the first in line to make an estimate."
      />
      <TeamProfile
        class="member"
        profileImage="../../assets/images/team/shari.jpg"
        bgImage="../../assets/images/team/shari-at-work.jpg"
        name="Shari Long"
        jobTitle="Owner"
        message="Shari, Johns wife, has worked at the shop as the secretary since 1989. She took off for a few years when she and John had children. Shari is the person who you are most likely to talk to when you give us a call."
      />
      <TeamProfile
        class="member"
        profileImage="../../assets/images/team/karlen.jpg"
        bgImage="../../assets/images/team/karlen-at-work.jpg"
        name="Karlen Morris"
        jobTitle="Asst. Manager"
        message="Like John, Karlen has worked in nearly every position at the shop. He actually holds a number of job titles, but we decided assistant manager fits best. Responsibilities include assisting shop management, parts manager, and working with insurance adjusters."
      />
      <TeamProfile
        class="member"
        profileImage="../../assets/images/team/cassie.jpg"
        bgImage="../../assets/images/team/millie.jpg"
        name="Cassie Long"
        jobTitle="Asst. Secretary"
        message="Cassie is the newest addition to the shop. Don't hold that against her though; she's sharp and always eager to help out. Cassie does all the odds and ins with getting your car looking back to how you want it!"
      />
    </div>
  </section>
</template>

<script>
import ShopSectionHeader from "@/components/atoms/ShopSectionHeader";
import TeamProfile from "@/components/molecules/TeamProfile";

export default {
  name: "ShopTeam",
  components: { ShopSectionHeader, TeamProfile },
};
</script>

<style lang="scss" scoped>
@import "../../styles/scss/placeholders";

section {
  @extend %container;
  padding-bottom: 5rem;
  padding-top: 5rem;
  .team {
    display: flex;
    flex-wrap: wrap;
    .member {
      margin: 16px;
    }
  }
}
</style>
