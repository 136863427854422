<template>
  <div class="header-top">
    <router-link to="/#home">
      <h1>JB Long and Son Body Shop</h1>
    </router-link>
    <div>
      <a href="tel:5026954477">
        <div class="phone">
          <font-awesome-icon icon="phone" />
          <div>
            <span class="message">Call Us Now</span>
            <span>(502) 695-4477</span>
          </div>
        </div>
      </a>
      <div class="hours-of-operation">
        <font-awesome-icon
          class="set-mode light"
          icon="clock"
          v-show="isDarkMode"
          v-on:click="setTheme('light')"
        />
        <font-awesome-icon
          class="set-mode dark"
          icon="clock"
          v-show="!isDarkMode"
          v-on:click="setTheme('dark')"
        />
        <div>
          <span class="message">Hours of Operations</span>
          <span>Mon - Fri 8:00am - 5:00pm</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getDatabase, ref, get, child } from "firebase/database";

export default {
  name: "HeaderTitle",
  methods: {
    setTheme(theme) {
      const html = document.querySelector("html");
      html.dataset.theme = `theme-${theme}`;
      this.isDarkMode = html.dataset.theme.includes("dark");

      const firebaseConfig = {
        apiKey: "API_KEY",
        authDomain: "jb-long-and-son-body-shop.firebaseapp.com",
        databaseURL:
          "https://jb-long-and-son-body-shop-default-rtdb.firebaseio.com/",
        projectId: "jb-long-and-son-body-shop",
        storageBucket: "jb-long-and-son-body-shop.appspot.com",
        messagingSenderId: "SENDER_ID",
        appId: "APP_ID",
        measurementId: "G-MEASUREMENT_ID",
      };

      const app = initializeApp(firebaseConfig);
      console.log(app);

      const database = getDatabase(app);
      console.log(database);

      const dbReference = ref(database);
      console.log(dbReference);

      get(child(dbReference, `/`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            console.log(snapshot.val());
          } else {
            console.warn("No data available at /");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  data() {
    return {
      isDarkMode() {
        const html = document.querySelector("html");
        return html.dataset.theme === "theme-dark";
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/scss/variables";
@import "../../styles/scss/placeholders";

* {
  transition: all ease-in-out 0.25s;
}

.header-top {
  @include userSelect(none);
  @extend %container;
  display: flex;
  justify-content: space-between;
  position: fixed;
  flex-wrap: wrap;
  top: 0;
  padding: 1rem;
  background-color: var(--bg-color);
  height: 50px;
  width: calc(100% - 2rem);
  box-shadow: 0 0 8px 5px transparentize($color: $black, $amount: 0.875);
  z-index: 3;
  a {
    text-decoration: none;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      color: var(--text-color);
      margin: 0;
      font-size: 1.5rem;
    }
  }
  div {
    display: flex;
    flex-wrap: wrap;
    .phone,
    .hours-of-operation {
      display: flex;
      align-items: center;
      padding: 0 1rem;

      .set-mode {
        cursor: pointer;

        &.light {
          filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
        }

        &.dark {
          filter: drop-shadow(1px 1px 1px rgba(255, 255, 255, 0.5));
        }
      }

      svg {
        color: var(--text-color);
        padding-right: 1rem;
        width: 25px;
        height: auto;
      }
      div {
        display: flex;
        flex-direction: column;
        text-align: left;
        span {
          color: var(--text-color);
          &.message {
            font-weight: bold;
          }
        }
      }
    }
    .phone {
      border-right: 2px solid $red;
    }
  }
}

@media (max-width: 800px) {
  .header-top {
    a {
      h1 {
        font-size: 1rem;
      }
    }
    div {
      .phone,
      .hours-of-operation {
        font-size: 0.75rem;
        padding: 0 0.5rem;
        svg {
          width: 15px;
          height: auto;
        }
      }
    }
  }
}

@media (max-width: 580px) {
  .header-top {
    justify-content: center;
  }
}

@media (max-width: 320px) {
  .header-top {
    div {
      .phone,
      .hours-of-operation {
        svg {
          padding-right: 0.5rem;
        }
      }
    }
  }
}

@media (max-width: 303px) {
  .header-top {
    padding: 1rem 0.5rem;
    width: calc(100% - 1rem);
  }
}
</style>
