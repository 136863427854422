<template>
  <header>
    <HeaderTitle/>
    <HeaderNavigation/>
  </header>
</template>

<script>
import HeaderTitle from "@/components/molecules/HeaderTitle";
import HeaderNavigation from "@/components/molecules/HeaderNavigation";

export default {
  name: "ShopPageHeader",
  components: {
    HeaderTitle,
    HeaderNavigation
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/scss/placeholders";
@import "../../styles/scss/variables";

header {
  @extend %container;
  display: flex;
  justify-content: center;
  background-color: $white;
  height: calc(82px + 50px); // top header + bottom header height
  position: fixed;
  top: 0;
  padding: 0;
  width: 100%;
  z-index: 1;
}
</style>
