<template>
  <div id="home">
    <ShopPageBanner id="main-slider" />
    <ShopServices id="about" />
    <ShopBookAppointment id="call-to-action" />
    <ShopTeam id="team" />
    <ShopTourGallery id="image-gallery" />
    <ShopGoogleMaps id="google-maps" />
  </div>
</template>

<script>
import ShopPageBanner from "@/components/organisms/ShopPageBanner";
import ShopServices from "@/components/organisms/ShopServices";
import ShopBookAppointment from "@/components/organisms/ShopBookAppointment";
import ShopGoogleMaps from "@/components/organisms/ShopGoogleMaps";
import ShopTourGallery from "@/components/organisms/ShopTourGallery";
import ShopTeam from "@/components/organisms/ShopTeam";

export default {
  name: "HomeVier",
  components: {
    ShopPageBanner,
    ShopServices,
    ShopBookAppointment,
    ShopGoogleMaps,
    ShopTourGallery,
    ShopTeam,
  },
};
</script>

<style lang="scss" scoped>
div {
  margin-top: 5rem;
}
</style>

