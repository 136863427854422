<template>
  <section>
    <GMapMap
      :center="center"
      :options="options"
      :zoom="15"
      map-type-id="roadmap"
      style="width: 100%; height: calc(100vh - 82px - 50px)"
    >
      <GMapCluster>
        <GMapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          :draggable="true"
          @click="center = m.position"
        />
      </GMapCluster>
    </GMapMap>
  </section>
</template>

<script>
export default {
  name: "ShopGoogleMaps",
  data() {
    return {
      center: { lat: 38.192279, lng: -84.827336 },
      markers: [
        {
          name: "JB Long and Son Body Shop",
          position: { lat: 38.192279, lng: -84.827336 },
        },
      ],
      places: [],
      currentPlace: null,
      options: {
        mapId: "ce0e9f8f9fa1609d",
      },
    };
  },

  mounted() {
    this.geolocate();
  },

  methods: {
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/scss/variables";

// always set the map height explicitly to define the size of the div element that contains the map.
#map {
  height: 100%;
}

section {
  border-top: 4px solid $red;
}
</style>
