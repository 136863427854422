<template>
  <section>
    <h1>Thanks for your submitting an application!</h1>
    <p>We will review your application and reach out soon.</p>
    <ShopButton
      link="/"
      topText="Return home"
      bottomText="Thanks again!"
      icon="smile-beam"
    />
  </section>
</template>

<script>
import ShopButton from "@/components/atoms/ShopButton";

export default {
  name: "SubmissionFail",
  components: {
    ShopButton,
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/scss/variables";
@import "../styles/scss/placeholders";

section {
  @extend %container;
  padding: 1.5rem 1rem;
  h1 {
    margin: 0;
  }
  p {
    margin: 0;
    margin-bottom: 1.5rem;
  }
}
</style>
