<template>
  <div class="app">
    <ShopPageHeader />
    <main>
      <router-view></router-view>
    </main>
    <ShopPageFooter />
  </div>
</template>

<script>
import ShopPageHeader from "@/components/organisms/ShopPageHeader";
import ShopPageFooter from "@/components/organisms/ShopPageFooter";

export default {
  name: "App",
  components: {
    ShopPageHeader,
    ShopPageFooter,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Open+Sans|Roboto");
@import "./styles/scss/variables";

body {
  margin: 0;
  margin-top: calc(82px + 50px); // top header + bottom header height
  overflow-x: hidden;
  background-color: var(--bg-color);
}

main {
  min-height: calc(
    100vh - 132px - 289.25px
  ); // minus height of header and footer
}

div.app {
  width: 100vw;
  box-shadow: inset -14px 0px 8px -16px $black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $titleFont;
  letter-spacing: 1px;
}

p,
span,
a {
  font-family: $bodyFont;
  letter-spacing: -1px;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: darken($color: $white, $amount: 15);
}

::-webkit-scrollbar-thumb {
  background: $blue;
}

::-webkit-scrollbar-thumb:hover {
  background: darken($color: $blue, $amount: 15);
}
</style>
